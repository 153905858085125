<template>
  <div>
    <b-card>
      <div class="row mb-4">
        <div class="col-12">
          <modal-actions @close="$router.push({ name: 'data' })" />
        </div>
      </div>

      <div class="shdow">
        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <p class="h2 text-secondary">Modifier objet métier</p>
          <b-button variant="secondary">Confirmer</b-button>
          <hr class="w-100" />
        </div>

        <div class="row">
          <!-- First Col -->
          <div class="col-12">
            <b-form-group
              label-cols="12"
              label="Libellé du concept"
              label-class="font-weight-bold"
            >
              <b-form-select
                :options="['Concept 1', 'Concept 2', 'Concept 3']"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Libellé objet métier"
              label-class="font-weight-bold"
            >
              <b-form-input type="text"></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row my-3">
          <div class="col-12 d-flex justify-content-end">
            <b-button
              variant="secondary"
              class="align-btn-table"
              @click="attributs.tableItems.push({ editMode: true })"
              ><i class="mdi mdi-plus"></i>Nouvel attribut</b-button
            >
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <b-table
              responsive
              :items="attributs.tableItems"
              :fields="attributs.tableFields"
            >
              <template v-slot:cell(dcp)="data">
                <span v-if="!data.item.hasOwnProperty('editMode')">
                  <b-badge v-if="data.value" variant="success">Oui</b-badge>
                  <b-badge v-else variant="danger">Non</b-badge>
                </span>
                <b-form-select v-else :options="['Oui', 'Non']" />
              </template>
              <template v-slot:cell(attributs)="data">
                <span v-if="!data.item.hasOwnProperty('editMode')">
                  {{ data.value }}
                </span>
                <b-form-input v-else placeholder="Attributes" />
              </template>
              <template v-slot:cell(sensible)="data">
                <span v-if="!data.item.hasOwnProperty('editMode')">
                  <b-badge v-if="data.value === 1" variant="success">{{
                    data.value
                  }}</b-badge>
                  <b-badge v-else-if="data.value === 2" variant="danger">{{
                    data.value
                  }}</b-badge>
                  <b-badge v-else-if="data.value === 3" variant="danger">{{
                    data.value
                  }}</b-badge>
                  <b-badge v-else variant="danger">{{ data.value }}</b-badge>
                </span>
                <b-form-select v-else :options="[1, 2, 3]" />
              </template>
              <template v-slot:cell(actions)="data">
                <table-actions
                  :actions="data.value"
                  :editMode="data.item.hasOwnProperty('editMode')"
                  @showItem="$emit('showItem')"
                  @editItem="$emit('editItem')"
                />
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import TableActions from "../../../../components/custom/TableActions.vue";

export default {
  components: {
    ModalActions,
    TableActions,
    // TableActions,
  },
  data: () => ({
    editMode: false,
    attributs: {
      tableFields: [
        { key: "attributs", label: "Attributes", sortable: true },
        { key: "dcp", label: "DCP", sortable: true },
        { key: "sensible", label: "Sensible", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
      tableItems: [
        {
          attributs: 152,
          dcp: 1,
          sensible: 1,
          actions: ["edit", "delete"],
        },
        {
          attributs: 21,
          dcp: 0,
          sensible: 2,
          actions: ["edit", "delete"],
        },
      ],
    },
  }),
  methods: {},
};
</script>

<style></style>
